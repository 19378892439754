import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { userSlice, } from '../slices';

import localStorage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session';

const persistConfiguration = {
  key: 'PB9c8f3@545!c8Of',
  storage: localStorage,
  blacklist: [],
};

// need to combine all the reducers here...
export const combinedReducer = combineReducers({
  user: userSlice.reducer,
});

export const persistedReducer = persistReducer(persistConfiguration, combinedReducer);
